<template>
  <div class="my-3 mx-4">
    <TenantAlert />
    <LocationAlert
      :locationId="+reservation.locationId"
      v-if="reservation.locationId"
    />
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.reservationCost"
    />
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Review</div>
        <br />
        <AdminReservationContact
          header="Reservation Customer"
          :contact="reservation"
        />
        <AdminReservationInfo />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationObserver ref="reservationCost">
          <AdminReservationCost
            :fees="fees"
            :reservation="reservation"
            :discount="discountReceived"
            permission="ReservationManagementOverrideOnAdd"
            :newReservation="true"
          />
        </ValidationObserver>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
          type="button"
          @click="goToReservationSearch"
          class="btn btn-cancel btn-lg mr-2 mb-2 float-right"
        >
          Cancel
        </button>
        <button
          type="button"
          @click="goBack"
          class="btn btn-lg btn-secondary mr-2 mb-2 float-right"
        >
          Back
        </button>
        <button
          type="button"
          @click="resetFees"
          class="btn btn-lg btn-secondary mr-2 mb-2 float-right"
          :disabled="!checkPermission('ReservationManagementOverrideOnAdd')"
        >
          Reset
        </button>
        <button
          role="button"
          class="btn btn-lg btn-primary mr-2 mb-2 float-right"
          @click="finish"
          :disabled="addingRes"
        >
          Add Reservation to Cart
        </button>
      </div>
    </div>
    <AdminOverrideAlertModal
      @override="overrideBusinessRule"
      header="Do you wish to override this business rule?"
    />
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationInfo from "@/components/admin/AdminReservationInfo.vue";
import AdminReservationCost from "@/components/admin/AdminReservationCost.vue";
import AdminCartService from "@/services/admin/AdminCartService.js";
import PricingService from "@/services/PricingService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TenantAlert from "@/components/alert/TenantAlert.vue";
import LocationAlert from "@/components/alert/LocationAlert.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

export default {
  name: "AdminReservationAddReview",
  title: "Admin - Reservation Add Review",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationInfo,
    AdminReservationCost,
    ValidationObserver,
    FormErrorAlert,
    TenantAlert,
    LocationAlert,
    AdminOverrideAlertModal
  },
  data() {
    return {
      fees: [],
      errors: [],
      addingRes: false
    };
  },
  methods: {
    goToReservationSearch() {
      this.$store.dispatch("transaction/clearReservation");
      this.$router.push("/admin/reservation-add").catch(() => {});
    },
    goBack() {
      this.$router.push("/admin/reservation-add-details").catch(() => {});
    },
    finish() {
      this.$refs.reservationCost.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.reservationCost.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.reservationCost.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          await this.onAddToCart();
        }
      });
    },
    resetFees() {
      this.fees.forEach(fee => {
        let feeTotal;
        if (fee.amount === 0) feeTotal = 0;
        else feeTotal = fee.amount * fee.quantity;
        this.$set(this.reservation, "fee" + fee.id, feeTotal.toFixed(2));
      });
      this.errors = [];
    },
    async getFees() {
      const tenantId = this.tenantId;
      const reservation = this.reservation;
      try {
        const pricingService = new PricingService(tenantId);
        const {
          spotId,
          startDate,
          endDate,
          numberOfAdults,
          numberOfVehicles,
          discounts
        } = reservation;

        const feeResponse = await pricingService.getFees(
          {
            customerId: reservation.customerId,
            spotId,
            startDate,
            endDate,
            numberOfAdults,
            numberOfVehicles,
            salesChannel: 2,
            discounts
          },
          0
        );
        if (feeResponse && feeResponse.statusCode === "Success") {
          const fees = [
            ...feeResponse.data.filter(x => x.feeType !== "Convenience")
          ];
          if (this.reservation.walkInReservation) {
            fees.forEach(f => {
              if (f.name == "Transaction Fee") {
                f.amount = 0;
              }
            });
          }
          this.fees = fees;
          this.resetFees();
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Fees weren't found",
            layer: "admin"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async onAddToCart() {
      this.$store.commit("auth/setLoading", true);
      this.addingRes = true;
      this.addToCart()
        .then(response => {
          if (response && response.statusCode === "Success") {
            this.$store.dispatch("transaction/incrementNumberOfItemsInCart");
            this.$store.dispatch("transaction/clearReservation");
            this.$router.push("shopping-cart");
          } else if (response?.statusCode == "Failure") {
            this.reservation.overrideBusinessRule = false;
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: response.messages[0],
              layer: "admin"
            });
            if (
              this.checkPermission(
                "ReservationManagementOverrideOnBusinessRules"
              )
            ) {
              this.$bvModal.show("override-modal");
            }
            return;
          }
        })
        .finally(() => {
          this.addingRes = false;
          this.$store.commit("auth/setLoading", false);
        });
    },
    async addToCart() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const request = this.mapReservationToRequest(this.reservation);
      try {
        const cartService = new AdminCartService(tenantId);

        //Check if reservation is already in the cart
        const cart = await cartService.getUserCart();

        const cartItem = cart.items.find(
          item => item.productItemId == this.reservation.id
        );
        if (cartItem) {
          //Remove reservation first
          await cartService.removeItemFromCart(cartItem.id, false);
          this.$store.dispatch("transaction/decrementNumberOfItemsInCart");
        }

        const result = cartService.addReservationToCart(request);
        return result;
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    mapReservationToRequest(reservation) {
      const feeOverrides = [];
      this.fees.forEach(fee => {
        let feeOverrideAmount = reservation["fee" + fee.id];
        if (+feeOverrideAmount != fee.amount * fee.quantity) {
          let feeOverride = { ...fee };
          feeOverride.amount = feeOverrideAmount;
          feeOverrides.push(feeOverride);
        }
      });

      const tenantId = this.$store.getters["tenant/tenantId"];

      const {
        spotId,
        startDate,
        endDate,
        numberOfAdults,
        numberOfVehicles,
        equipmentLength,
        equipmentType,
        firstName,
        lastName,
        street1,
        street2,
        city,
        state,
        zipcode,
        email,
        mobilePhone,
        lockCode,
        discounts,
        customerId,
        overrideBusinessRule,
        walkInReservation,
        adaRequirementAcknowledged,
        overrideClosure
      } = reservation;
      const spotEquipmentType = reservation.spot.spotEquipmentTypes.find(
        x => x.equipmentType.name === equipmentType
      );
      const request = {
        tenantId,
        customerId: customerId,
        spotId,
        startDate,
        endDate,
        numberOfAdults,
        numberOfVehicles,
        discounts: [...discounts],
        equipmentLength: +equipmentLength,
        equipmentTypeId: spotEquipmentType.equipmentType.id,
        isCustomerPrimaryOccupant: true,
        primaryOccupantFirstName: firstName,
        primaryOccupantLastName: lastName,
        primaryOccupantStreet1: street1,
        primaryOccupantStreet2: street2,
        primaryOccupantCity: city,
        primaryOccupantState: state,
        primaryOccupantZipcode: zipcode,
        primaryOccupantCountry: "USA",
        primaryOccupantEmail: email,
        primaryOccupantHomePhone: null,
        primaryOccupantMobilePhone: mobilePhone,
        lockCode,
        feeOverrides: feeOverrides,
        overrideBusinessRule,
        walkInReservation,
        adaRequirementAcknowledged,
        overrideClosure
      };
      return request;
    },
    overrideBusinessRule() {
      this.$store.dispatch("alert/clear");
      this.reservation.overrideBusinessRule = true;
      this.onAddToCart();
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    totalAmountDue() {
      return this.fees.reduce((accumulator, fee) => {
        if (
          this.reservation["fee" + fee.id] != "" &&
          !isNaN(this.reservation["fee" + fee.id])
        ) {
          return accumulator + Number(this.reservation["fee" + fee.id]);
        }
        return accumulator;
      }, 0);
    },
    discountReceived() {
      let initialTotal = 0;
      this.fees.forEach(fee => {
        initialTotal += fee.amount * fee.quantity;
      });
      let discount = initialTotal - this.totalAmountDue;
      return discount > 0 ? discount.toFixed(2) : "0.00";
    }
  },
  created() {
    this.getFees();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
</style>
