<template>
  <div class="card">
    <div class="card-header">
      <h2 class="card-title mt-2 mb-0">Cost</h2>
    </div>
    <div class="card-body">
      <div class="row" v-for="fee in fees" :key="fee.id">
        <div class="col-12">
          <MoneyInput
            class="mb-1"
            :rules="'required|between:0,' + feeTotal(fee)"
            :name="fee.name"
            :id="'' + fee.id"
            v-model="reservation['fee' + fee.id]"
            @blur="feeChange(fee)"
            :disabled="
              (permission != null && !checkPermission(permission)) ||
                (fee.name === 'Transaction Fee' && newReservation) ||
                (fee.name === 'Modification Fee' && fee.amount == 0)
            "
          />
          <p class="text-muted">
            Original {{ fee.name }}: {{ feeTotal(fee) | formatCurrency }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <TextInput
            name="Discount Received"
            id="discountReceived"
            v-model="discount"
            :readonly="true"
          />
        </div>
      </div>
    </div>
    <AdminOverrideAlertModal
      header="Do you wish to override this fee?"
      modalRef="override-fee-modal"
      :overriddenProp="overriddenFee"
      @cancel="overrideCancel"
    />
  </div>
</template>

<script>
import MoneyInput from "@/validation/MoneyInput.vue";
import TextInput from "@/validation/TextInput.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

export default {
  name: "AdminReservationCost",
  mixins: [checkPermissionMixin],
  components: {
    MoneyInput,
    TextInput,
    AdminOverrideAlertModal
  },
  data() {
    return {
      overriddenFee: {}
    };
  },
  methods: {
    feeTotal(fee) {
      if (fee.amount === 0) return 0;
      else return fee.amount * fee.quantity;
    },
    overrideCancel(fee) {
      this.reservation["fee" + fee.id] = fee.originalAmount;
    },
    feeChange(fee) {
      const reservationFeeAmount = this.reservation["fee" + fee.id];
      if (fee.amount == reservationFeeAmount) return;

      this.overriddenFee = {
        name: fee.name,
        value: reservationFeeAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        }),
        id: fee.id,
        originalAmount: fee.amount
      };
      this.$bvModal.show("override-fee-modal");
    }
  },
  props: {
    fees: Array,
    reservation: Object,
    discount: [Number, String],
    permission: String,
    newReservation: Boolean
  }
};
</script>

<style scoped>
.text-muted {
  font-size: small;
}
</style>
