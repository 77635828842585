<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :rules="rules"
    :name="name"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :bails="false"
    :immediate="immediate"
    :mode="mode"
  >
    <label
      @click="$refs.input.focus()"
      :for="id"
      :class="{ error: errors[0] }"
      v-if="!hideLabel"
    >
      <span>{{ name }}</span>
      <span class="error">{{ required ? " *" : "" }}</span>
    </label>
    <money
      v-model="innerValue"
      v-bind="{ ...ariaInput, ...money }"
      class="form-control"
      :class="formControlSizeClass"
      ref="input"
      :id="id"
      type="text"
      @keydown.tab="tabPressed"
      @change="onChange"
      @blur.native="onBlur"
      :aria-label="id"
      :disabled="disabled"
    ></money>
    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
      <li
        v-for="(error, index) in errors"
        :key="index"
        class="error"
        v-bind="ariaMsg"
      >
        {{ error }}
      </li>
    </ul>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { Money } from "v-money";

export default {
  name: "MoneyInput",
  components: { Money, ValidationProvider },
  props: {
    vid: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    size: {
      type: String,
      default: "lg"
    },
    value: {
      type: null,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    immediate: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "aggressive"
    }
  },
  data() {
    return {
      innerValue: "",
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  methods: {
    tabPressed() {
      this.$emit("tab");
    },
    onChange(e) {
      this.$emit("change", e);
    },
    onBlur(e) {
      this.$emit("blur", e);
    }
  },
  computed: {
    hasValue() {
      return !!this.innerValue;
    },
    formControlSizeClass() {
      return "form-control-" + this.size;
    }
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
